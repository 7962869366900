import { Component, OnInit } from '@angular/core';
import { AuthService } from "./modulos/auth/servicios/auth.service";
import { IuController } from "./modulos/sistema/controladores/iu/iu.controller";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../environments/environment';

const urlProductos = `${environment.url_base}${environment.url_producto}`;
const urlClientes = `${environment.url_base}${environment.url_clientes}`;
const urlCategorias = `${environment.url_base}${environment.url_categoria}`;

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  public es_usuario_logueado = false;
  public categoriaCantidad: number = 0;
  public productosCantidad: number = 0;
  public clientesCantidad: number = 0;
  public email: string = "";
  public nombreUsuario: string = "";

  public appPages = [
    { title: 'Categorías', url: '/categorias', icon: 'copy', cantidad: this.categoriaCantidad  },
    { title: 'Productos', url: '/productos', icon: 'cube', cantidad: this.productosCantidad },
    { title: 'Clientes', url: '/clientes', icon: 'person-circle', cantidad: this.clientesCantidad },
    { title: 'Informes Contratos', url: '/informes', icon: 'document' },
    { title: 'Logs', url: '/log', icon: 'book' },
    { title: 'Versión: 1.3', url: '#', icon: 'cloud' },
  ];

  constructor(
    private _authService: AuthService,
    private _iuController: IuController,
    private http: HttpClient
  ) {}

  ngOnInit() {
    this.es_usuario_logueado = this._authService.estaAutenticado();
    this._authService.usuarioModificado$.subscribe(() => this.es_usuario_logueado = this._authService.estaAutenticado());

    // Llamamos a la función para obtener los datos una vez al inicio
    this.obtenerCantidad();
    this.ObtenerEmail();
    setInterval(() => {this.ObtenerEmail();}, 4000);
    setInterval(() => {this.obtenerCantidad();}, 5000);
  }

  ObtenerEmail(){
    const emailUsuario = localStorage.getItem("Usuario");
    if (emailUsuario !== null) {
      this.email = emailUsuario;
      switch(emailUsuario){
        case 'administracion@heladosrueda.com': this.nombreUsuario = 'Administración'; break;
        case 'pueblos1@heladosrueda.com': this.nombreUsuario = 'Pueblos 1'; break;
        case 'pueblos2@heladosrueda.com': this.nombreUsuario = 'Pueblos 2'; break;
        case 'rutaalbacete@heladosrueda.com': this.nombreUsuario = 'Ruta Albacete'; break;
      }

    }
  }
  
  logout() {
    this._authService.logout();
    this._iuController.navegar("login");
    this.email = null;
    this.nombreUsuario = null;
    localStorage.removeItem("Usuario");
  }

  /**
   * Función para obtener la cantidad de productos o clientes que hay en la aplicación
   * y actualizar la cantidad en appPages
   * @param url 
   */
  async obtenerCantidad() {
    const URLCategorias = urlCategorias;
    const URLProductos = urlProductos;
    const URLClientes = urlClientes;
    const token = this._authService.obtenerToken();
    const headers = {
        'Authorization': `Bearer ${token}`
    };

    try {
        const categoriaResponse = await fetch(URLCategorias, { headers });
        const productosResponse = await fetch(URLProductos, { headers });
        const clientesResponse = await fetch(URLClientes, { headers });

        if (productosResponse.ok && clientesResponse.ok && categoriaResponse.ok) {
            const categoriaData = await categoriaResponse.json();
            const productosData = await productosResponse.json();
            const clientesData = await clientesResponse.json();
            this.appPages[0].cantidad = categoriaData['hydra:totalItems'];
            this.appPages[1].cantidad = productosData['hydra:totalItems'];
            this.appPages[2].cantidad = clientesData['hydra:totalItems'];
        } else {
            console.error("Error al obtener datos:", productosResponse.statusText, clientesResponse.statusText);
        }
    } catch (error) {
        console.error("Error al obtener datos:", error);
    }
}
  
}

