import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import {AutenticadoGuard} from "./modulos/auth/servicios/autenticado.guard";

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'login',
    loadChildren: () => import('./paginas/auth/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'recuperar-password',
    loadChildren: () => import('./paginas/auth/recuperar-password/recuperar-password.module').then( m => m.RecuperarPasswordPageModule)
  },
  {
    path: 'productos/:id',
    loadChildren: () => import('./paginas/productos/producto/producto.module').then( m => m.ProductoPageModule),
    canActivate: [AutenticadoGuard]
  },
  {
    path: 'productos',
    loadChildren: () => import('./paginas/productos/productos/productos.module').then( m => m.ProductosPageModule),
    canActivate: [AutenticadoGuard]
  },
  {
    path: 'categorias/:id',
    loadChildren: () => import('./paginas/categoria/categoria/categoria.module').then( m => m.CategoriaPageModule),
    canActivate: [AutenticadoGuard]
  },
  {
    path: 'categorias',
    loadChildren: () => import('./paginas/categoria/categorias/categorias.module').then( m => m.CategoriasPageModule),
    canActivate: [AutenticadoGuard]
  },
  {
    path: 'clientes',
    loadChildren: () => import('./paginas/clientes/clientes/clientes.module').then( m => m.ClientesPageModule),
    canActivate: [AutenticadoGuard]
  },
  {
    path: 'clientes/:id',
    loadChildren: () => import('./paginas/clientes/cliente/cliente.module').then( m => m.ClientePageModule),
    canActivate: [AutenticadoGuard]
  },
  {
    path: 'clientes/cliente-tabs/:id',
    loadChildren: () => import('./paginas/clientes/cliente-tabs/cliente-tabs.module').then( m => m.ClienteTabsPageModule),
    canActivate: [AutenticadoGuard]
  },
  {
    path: 'contratos-cesion/contrato-cesion/:id',
    loadChildren: () => import('./paginas/contratos-cesion/contrato-cesion/contrato-cesion.module').then( m => m.ContratoCesionPageModule),
    canActivate: [AutenticadoGuard]
  },
  {
    path: 'clientes/:idCliente/contratos-cesion/contrato-cesion/:id',
    loadChildren: () => import('./paginas/contratos-cesion/contrato-cesion/contrato-cesion.module').then( m => m.ContratoCesionPageModule),
    canActivate: [AutenticadoGuard]
  },
  {
    path: 'firma/entregar',
    loadChildren: () => import('./paginas/firma/firma.module').then( m => m.FirmaPageModule),
    canActivate: [AutenticadoGuard]
  },
  {
    path: 'firma/recoger',
    loadChildren: () => import('./paginas/firma/firma.module').then( m => m.FirmaPageModule),
    canActivate: [AutenticadoGuard]
  },
  /*{
    path: 'cliente-contratos',
    loadChildren: () => import('./paginas/clientes/cliente-contratos/cliente-contratos.module').then( m => m.ClienteContratosPageModule)
  }*/
  {
    path: 'log',
    loadChildren: () => import('./paginas/log/log.module').then( m => m.LogPageModule),
    canActivate: [AutenticadoGuard]
  },
  {
    path: 'informes',
    loadChildren: () => import('./paginas/informes/informes.module').then( m => m.InformesPageModule),
    canActivate: [AutenticadoGuard]
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
